import React from "react";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

const PaymentButton = () => {
    const { error, isLoading, Razorpay } = useRazorpay();

    const handlePayment = () => {
        const options = {
            key: "YOUR_RAZORPAY_KEY",
            amount: 50000, // Amount in paise
            currency: "INR",
            name: "Test Company",
            description: "Test Transaction",
            order_id: "order_9A33XWu170gUtm", // Generate order_id on server
            handler: (response) => {
                console.log(response);
                alert("Payment Successful!");
            },
            prefill: {
                name: "John Doe",
                email: "john.doe@example.com",
                contact: "9999999999",
            },
            theme: {
                color: "#F37254",
            },
        };

        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
    };

    return (
        <div>
            <h1>Payment Page</h1>
            {isLoading && <p>Loading Razorpay...</p>}
            {error && <p>Error loading Razorpay: {error}</p>}
            <button className="bg-black text-white px-4 py-2" onClick={handlePayment} disabled={isLoading}>
                Pay Now
            </button>
        </div>
    );
};

export default PaymentButton;
