import React from 'react'
import FeatureHero from '../components/FeatureHero'
import FeatureComponent from '../components/featureComponent'
import Footer from '../components/footer'
import Message from '../components/message'
import FAQ from '../components/faq'

function Feature() {
    return (
        <div>
            <FeatureHero />
            <FeatureComponent />
            <Message />
            <FAQ />
            <Footer />
        </div>
    )
}

export default Feature