import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detail: [],
};

const visitorDetailsSlice = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    setVisitorDetail: (state, action) => {
      state.detail = action.payload || state.detail;
    },
    clearVisitorDetail: (state) => {
      state.detail = [];
    },
  },
});

export const { setVisitorDetail, clearVisitorDetail } = visitorDetailsSlice.actions;
export default visitorDetailsSlice.reducer;
