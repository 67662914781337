import React from 'react'

function HowWorking() {
    return (
        <div>
            <section class="py-10 bg-white ">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">How does it work?</h2>
                        <p class=" mx-auto mt-4 text-base leading-relaxed text-gray-600">Helping our clients in achieving a 10X increase in conversion rates.</p>
                    </div>

                    <div class="relative mt-12 lg:mt-20">
                        <div class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                            <img class="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="" />
                        </div>

                        <div class="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                            <div>
                                <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span class="text-xl font-semibold text-gray-700"> 1 </span>
                                </div>
                                <h3 class="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Create a account</h3>
                                <p class="mt-4 text-base text-gray-600">Create your account, choose the perfect package that suits your needs, and provide the necessary details to get started—it's that simple to unlock the full potential of Tipflow.</p>
                            </div>

                            <div>
                                <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span class="text-xl font-semibold text-gray-700"> 2 </span>
                                </div>
                                <h3 class="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Connect your website</h3>
                                <p class="mt-4 text-base text-gray-600">Simply integrate the provided JS snippet into your client-side code, and you're all set—Tipflow will handle the rest effortlessly.</p>
                            </div>

                            <div>
                                <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                    <span class="text-xl font-semibold text-gray-700"> 3 </span>
                                </div>
                                <h3 class="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Get more leads</h3>
                                <p class="mt-4 text-base text-gray-600">Tipflow tracks visitor data in real-time, handling event logging, customer analytics, and conversion tracking automatically—driving noticeable improvements in customer engagement and purchases.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default HowWorking;