import React from 'react';
import PaymentButton from '../components/payment/Payu';
import { PlanCard } from '../components/pricing';

// const PlanCard = ({ title, price, features }) => (
//     <div class="w-full  h-full">
//         <div
//             class="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke dark:border-dark-3 bg-white dark:bg-dark-2 py-10 px-8 shadow-pricing"
//         >
//             <span class="mb-3 block text-lg font-semibold text-primary">
//                 {title}
//             </span>
//             <h2 class="mb-5 text-[32px] font-bold text-dark">
//                 <span>{price}</span>
//                 <span class="text-base font-medium text-body-color ">
//                     / year
//                 </span>
//             </h2>
//             <p
//                 class="mb-8 border-b border-stroke dark:border-dark-3 pb-8 text-base text-body-color dark:text-dark-6"
//             >
//                 Perfect for using in a Business website or a client project.
//             </p>
//             <div class="mb-9 flex flex-col gap-[14px]">
//                 {features.map((item, index) => (
//                     <p class="text-base text-body-color dark:text-dark-6">
//                         {item}
//                     </p>
//                 ))}
//             </div>
//             <a
//                 href="javascript:void(0)"
//                 class="block w-full rounded-md border border bg-indigo-600 p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
//             >
//                 Purchase Plan
//             </a>
//             <div>
//                 <span class="absolute right-0 top-7 z-[-1]">
//                     <svg
//                         width="77"
//                         height="172"
//                         viewBox="0 0 77 172"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
//                         <defs>
//                             <linearGradient
//                                 id="paint0_linear"
//                                 x1="86"
//                                 y1="0"
//                                 x2="86"
//                                 y2="172"
//                                 gradientUnits="userSpaceOnUse"
//                             >
//                                 <stop stop-color="#3056D3" stop-opacity="0.09" />
//                                 <stop
//                                     offset="1"
//                                     stop-color="#C4C4C4"
//                                     stop-opacity="0"
//                                 />
//                             </linearGradient>
//                         </defs>
//                     </svg>
//                 </span>
//                 <span class="absolute right-4 top-4 z-[-1]">
//                     <svg
//                         width="41"
//                         height="89"
//                         viewBox="0 0 41 89"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <circle
//                             cx="38.9138"
//                             cy="87.4849"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 87.4849)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="74.9871"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 74.9871)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="62.4892"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 62.4892)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="38.3457"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 38.3457)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="13.634"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 13.634)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="50.2754"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 50.2754)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="26.1319"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 26.1319)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="38.9138"
//                             cy="1.42021"
//                             r="1.42021"
//                             transform="rotate(180 38.9138 1.42021)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="87.4849"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 87.4849)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="74.9871"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 74.9871)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="62.4892"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 62.4892)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="38.3457"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 38.3457)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="13.634"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 13.634)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="50.2754"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 50.2754)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="26.1319"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 26.1319)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="26.4157"
//                             cy="1.4202"
//                             r="1.42021"
//                             transform="rotate(180 26.4157 1.4202)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="87.4849"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 87.4849)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="74.9871"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 74.9871)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="62.4892"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 62.4892)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="38.3457"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 38.3457)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="13.634"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 13.634)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="50.2754"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 50.2754)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="26.1319"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 26.1319)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="13.9177"
//                             cy="1.42019"
//                             r="1.42021"
//                             transform="rotate(180 13.9177 1.42019)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="87.4849"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 87.4849)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="74.9871"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 74.9871)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="62.4892"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 62.4892)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="38.3457"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 38.3457)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="13.634"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 13.634)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="50.2754"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 50.2754)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="26.1319"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 26.1319)"
//                             fill="#3056D3"
//                         />
//                         <circle
//                             cx="1.41963"
//                             cy="1.4202"
//                             r="1.42021"
//                             transform="rotate(180 1.41963 1.4202)"
//                             fill="#3056D3"
//                         />
//                     </svg>
//                 </span>
//             </div>
//         </div>
//     </div>
// );

const plans = [
    {
        id: 1,
        name: 'Basic Plan',
        price: '$10/month',
        features: ['Feature A', 'Feature B', 'Feature C'],
        isCurrent: true,
    },
    {
        id: 2,
        name: 'Premium Plan',
        price: '$20/month',
        features: ['Feature A', 'Feature B', 'Feature C', 'Feature D', 'Feature E'],
        isCurrent: false,
    },
];

// New dummy data for the different card
const userDetails = {
    websiteName: 'example.com',
    currentPlan: 'Basic Plan',
    username: 'john_doe',
};

function Payment() {

    const starterPlan = [
        "Free Website Analysis & Consultancy",
        "Limited SEO Optimization & Page Ranking",
        "Basic Click-Through Analytics & User Behavior Insights",
        "Limited Email Automation & Meeting Scheduling",
        "Basic CRM Integration",
        "upto 3 websites",
    ];

    const proPlan = [
        "Free Website Analysis & Consultancy",
        "Full SEO Optimization & Page Ranking",
        "Advanced Click-Through Analytics & User Behavior Insights",
        "Personalized Chatbots Powered by GEMINI LLM",
        "AI-Driven LinkedIn Prospecting",
        "Full Email Automation & Meeting Scheduling",
        "Advanced CRM Integration",
        "Custom Reporting",
        "Priority Support",
        "upto 5 websites",
    ];

    const businessPlan = [
        "Customized as per needs",
        "Includes all features in pro package"
    ];

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white p-6">
            <h1 className="text-2xl font-bold text-indigo-600 mb-6">Payment Plans</h1>
            <div className=''>
                <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                    <div className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-[50px]">
                        <span className="mb-3 block text-lg font-semibold text-primary">User Details</span>
                        <h2 className="mb-5 text-[32px] font-bold text-dark">{userDetails.websiteName}</h2>
                        <p className="mb-4 text-base text-body-color">Current Plan: {userDetails.currentPlan}</p>
                        <p className="mb-4 text-base text-body-color">Username: {userDetails.username}</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                    <PlanCard title="Starter Plan" price="$70" features={starterPlan} />
                    <PlanCard title="Pro Plan" price="$150" features={proPlan} />
                    <PlanCard title="Business Plan" price="Custom" features={businessPlan} />
                    <PaymentButton />
                </div>
            </div>

        </div>
    );
}

export default Payment;
