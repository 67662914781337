
import React from "react";
import { Chart } from "react-google-charts";
import { useSelector } from 'react-redux';

export default function MyComponent() {

    const visitorDetail = useSelector((state) => state.visitor);

    const data = [
        ["Element", "", { role: "style" }],
        ["visitor converted", visitorDetail.nb_visits_converted, "#382cdd"],
        ["Total Visitor", visitorDetail.nb_visits, "#2d70f5"],

    ];
    return (
        <div className="h-[300px] w-[350px] border m-2 shadow">
            <Chart chartType="ColumnChart" width="100%" height="100%" data={data} />
        </div>
    );
}