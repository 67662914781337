import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; 
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Login failed');
  }
};

export const signup = async (userData) => {
    try {
      const res = await axios.post(`${API_URL}/auth/register`, userData);
      return res.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || 'An error occurred.');
    }
  };