import axios from 'axios';
import mongoose from 'mongoose';

const { ObjectId } = mongoose.Types;

const API_URL = process.env.REACT_APP_API_URL;
// const MATOMO_API_URL = 'http://localhost:8081/index.php?';
// const MATOMO_TOKEN = '8e6d2618888d7ff5738941107475fd26';

const MATOMO_API_URL = 'https://matomo-adrig.centralindia.cloudapp.azure.com/index.php?';
const MATOMO_TOKEN = '173ff6d2402b419aeea92b71e37d44b0';

export const addSiteInMatomo = async (siteName, url, userId) => {
  try {
    const response = await addSite(siteName, url);
    console.log(response.data)
    if (response.status === 200 && response?.data?.value) {
      const idSite = response.data.value;
      const access = await giveSiteAccess(String(response.data.value));
      console.log(access, access.result)
      if (access.result === 'success') {
        const userIdObj = new ObjectId(userId);
        const res = await axios.post(`${API_URL}/site/`, {
          websiteName: siteName,
          userId: userIdObj,
          idSite,
          url,
        });
        return res.data;
      }
    } else {
      throw new Error("Failed to add site");
    }
  } catch (error) {
    throw new Error("Error occurred while adding site: " + error.message);
  }
};

const addSite = async (siteName, urls) => {
  try {
    if (!siteName || !urls) {
      throw new Error('Missing or invalid parameters');
    }

    let requestParams = new URLSearchParams();
    requestParams.append('module', 'API');
    requestParams.append('format', 'json');
    requestParams.append('method', 'SitesManager.addSite');
    requestParams.append('siteName', siteName);
    requestParams.append('idSite', 1);
    requestParams.append('urls[]', urls);
    requestParams.append('token_auth', MATOMO_TOKEN);

    const response = await axios.post(MATOMO_API_URL, requestParams.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response;
  } catch (err) {
    console.error('Error updating site:', err);
    throw new Error('Internal Server Error');
  }
};

const giveSiteAccess = async (idSite) => {
  try {
    if (!idSite) {
      throw new Error('Missing or invalid parameters');
    }

    let requestParams = new URLSearchParams();
    requestParams.append('module', 'API');
    requestParams.append('format', 'json');
    requestParams.append('method', 'UsersManager.setUserAccess');
    requestParams.append('userLogin', 'anonymous');
    requestParams.append('access', 'view');
    requestParams.append('idSites', idSite);
    requestParams.append('passwordConfirmation', 'Qwer1234@');
    requestParams.append('token_auth', MATOMO_TOKEN);

    const response = await axios.post(MATOMO_API_URL, requestParams.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data;
  } catch (err) {
    console.error('Error updating site:', err);
    throw new Error('Internal Server Error');
  }
};


export const getVisitorInfo = async (idSite) => {
  if (!idSite) {
    throw new Error('Missing or invalid parameters');
  }
  const requestParams = new URLSearchParams();
  requestParams.append('module', 'API');
  requestParams.append('format', 'JSON');
  requestParams.append('method', 'VisitsSummary.get');
  requestParams.append('idSite', idSite);
  requestParams.append('period', 'day');
  requestParams.append('date', 'today');
  requestParams.append('force_api_session', '1');
  requestParams.append('token_auth', 'anonymous');
  const piwikResponse = await axios.get(`${MATOMO_API_URL}${requestParams.toString()}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  console.log(piwikResponse);
  return piwikResponse.data
}

export const getVisitorDetails = async (idSite, period = 'day') => {
  if (!idSite) {
    throw new Error('Missing or invalid parameters');
  }
  const requestParams = new URLSearchParams();
  requestParams.append('module', 'API');
  requestParams.append('format', 'JSON');
  requestParams.append('method', 'Live.getLastVisitsDetails');
  requestParams.append('idSite', idSite);
  requestParams.append('period', period);
  requestParams.append('date', 'today');
  requestParams.append('force_api_session', '1');
  requestParams.append('token_auth', 'anonymous');
  const visitorDetails = await axios.get(`${MATOMO_API_URL}${requestParams.toString()}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  console.log(visitorDetails.data, "sdsdsdSdsdsdsd");
  return visitorDetails.data
}