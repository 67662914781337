import React from 'react'

function Message() {
    return (
        <div
            className='h-52 mx-12 my-8 rounded-lg px-12 flex flex-col justify-center '
            style={{
                background: 'linear-gradient(90deg,rgba(115,20,247,1)  0%, rgba(37,146,252,1) 100%)',
            }}
        >
            <div className='text-white font-black text-[52px]'>Having Any Doubt?</div>
            <div className='flex justify-between'>
                <div className='text-white font-light text-[22px]'>Feel free to contact</div>
                <button className='border px-8 py-2 rounded-xl bg-white text-lg font-bold'>Contact</button>
            </div>
        </div>

    )
}

export default Message