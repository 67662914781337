import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/tip-1.png';

function Footer() {
    const navigate = useNavigate();

    const footerNavs = [
        { path: '/features', name: 'Features' },
        { path: '/pricing', name: 'Pricing' },
        { path: '/blog', name: 'Blog' },
        { path: '/b2b-community', name: 'B2b Community' }
    ];

    const socialLinks = [
        {
            href: "javascript:void(0)",
            color: "text-blue-400",
            svg: (
                <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M22.46 6c-.77.35-1.6.58-2.46.69a4.3 4.3 0 001.9-2.38 8.58 8.58 0 01-2.72 1.04A4.28 4.28 0 0016.4 4a4.28 4.28 0 00-4.3 4.28c0 .33.04.65.1.96a12.15 12.15 0 01-8.82-4.47 4.28 4.28 0 001.32 5.72A4.28 4.28 0 012 9.8v.04a4.28 4.28 0 003.43 4.2 4.26 4.26 0 01-1.94.07 4.28 4.28 0 004 2.97A8.6 8.6 0 012 19.7a12.17 12.17 0 006.58 1.93c7.88 0 12.2-6.52 12.2-12.18 0-.19 0-.37-.01-.55A8.77 8.77 0 0024 4.57a8.6 8.6 0 01-2.54.7z" />
                </svg>
            )
        },
        {
            href: "javascript:void(0)",
            color: "text-blue-700",
            svg: (
                <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.129 8.438 9.876v-6.987h-2.54v-2.889h2.54V9.797c0-2.508 1.493-3.89 3.777-3.89 1.094 0 2.238.194 2.238.194v2.461h-1.26c-1.243 0-1.63.771-1.63 1.562v1.875h2.773l-.443 2.89h-2.33v6.987C18.343 21.13 22 16.991 22 12z" />
                </svg>
            )
        },
        {
            href: "javascript:void(0)",
            color: "text-blue-500",
            svg: (
                <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M4.98 3.5A2.5 2.5 0 007.48 1 2.5 2.5 0 1010 3.5v0A2.5 2.5 0 007.5 6a2.5 2.5 0 00-2.52-2.5zM3.5 8h3v12h-3V8zm6 0h3v1.73h.05a3.4 3.4 0 013.05-1.73c3.26 0 3.87 2.15 3.87 4.94v5.06h-3V13.5c0-2.1-.05-3.82-2.33-3.82-2.33 0-2.7 1.81-2.7 3.7v5.12h-3V8z" />
                </svg>
            )
        },
        {
            href: "javascript:void(0)",
            color: "text-red-600",
            svg: (
                <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M13.786 12.016c-.215-.172-.32-.413-.262-.66.101-.476.693-2.676.77-3.505.084-.882-.211-1.392-.817-1.292-.71.116-1.19.985-1.39 1.616-.275.85-.668 2.488-.668 2.488-.08.304-.28.358-.497.298-.535-.148-1.084-.645-1.259-1.173-.35-1.117.318-2.532 1.094-3.079.955-.684 2.322-.426 3.168-.008.916.454 1.527 1.488 1.637 2.563.13 1.28-.32 2.28-1.025 3.016-.63.63-1.38 1.142-2.2 1.51l-.007.004c-.272.12-.564.263-.812.422-.346.225-.62.44-.857.687-.516.542-1.166 1.172-1.475 1.957a4.63 4.63 0 01-1.47-2.412 5.956 5.956 0 01-.004-.096c0-1.396 1.153-2.606 2.633-2.606.673 0 1.306.286 1.786.79zM10 2.25c4.28 0 7.75 3.47 7.75 7.75 0 4.28-3.47 7.75-7.75 7.75S2.25 14.28 2.25 10 5.72 2.25 10 2.25z" />
                </svg>
            )
        }
    ];


    return (
        <footer className="text-gray-500 bg-white px-4 py-10 max-w-screen-xl mx-auto md:px-8">
            <div className="max-w-xl sm:mx-auto sm:text-center">
                <div className="shrink-0 flex justify-center mb-6">
                    <a
                        className="relative flex items-center sm:order-1 text-black flex-none text-xl font-bold focus:outline-none focus:opacity-80"
                        onClick={() => navigate('/')}
                    >
                        <img className="absolute -top-[20px] -left-[34px] h-[60px]" src={Logo} alt="logo" />
                        ipflow
                    </a>
                </div>
                <p className="leading-relaxed mt-2 text-[15px]">
                    Join us in transforming your customer interactions and driving your success forward. For inquiries or support, feel free to reach out to us anytime.
                </p>
            </div>

            <ul className="items-center justify-center mt-8 space-y-5 sm:flex sm:space-x-4 sm:space-y-0">
                {footerNavs.map((item, idx) => (
                    <li key={idx} className="hover:text-gray-800">
                        <a
                            href="javascript:void(0)"
                            onClick={() => navigate(item.path)}
                        >
                            {item.name}
                        </a>
                    </li>
                ))}
            </ul>

            <div className="mt-8 items-center justify-between sm:flex">
                <div className="mt-4 sm:mt-0">&copy; 2024 Tipflow. All rights reserved.</div>
                <div className="mt-6 sm:mt-0">
                    <ul className="flex items-center space-x-4">
                        {socialLinks.map((link, index) => (
                            <li key={index} className="w-10 h-10 border rounded-full flex items-center justify-center">
                                <a href={link.href}>
                                    <div className={link.color}>
                                        {link.svg}
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
