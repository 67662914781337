import React from 'react';

import { useSelector } from 'react-redux';

function UserCount() {
    const visitorDetail = useSelector((state) => state.visitor);

    return (
        // <div className='w-full'>
        <div className='w-full flex space-x-4 h-auto items-center m-2 mt-4'>
            <div className='flex flex-col justify-around  bg-white w-full h-24 border px-4 py-2 shadow-md'>
                <div className='flex justify-between pr-4'>
                    <div className='text-md font-light'>Total Visitor</div>
                    <div >:</div>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='text-3xl font-semibold'>{visitorDetail.nb_visits}</div>
                    <div>16% ^</div>
                </div>
            </div>
            <div className='flex flex-col justify-around  bg-white w-full h-24 border px-4 py-2 shadow-md'>
                <div className='flex justify-between pr-4'>
                    <div className='text-md font-light'>Unique Visitor</div>
                    <div >:</div>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='text-3xl font-semibold'>{visitorDetail.nb_uniq_visitors}</div>
                    <div>16% ^</div>
                </div>
            </div>
            <div className='flex flex-col justify-around  bg-white w-full h-24 border px-4 py-2 shadow-md'>
                <div className='flex justify-between pr-4'>
                    <div className='text-md font-light'>Total Action</div>
                    <div >:</div>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='text-3xl font-semibold'>{visitorDetail.nb_actions}</div>
                    <div>16% ^</div>
                </div>
            </div>
            <div className='flex flex-col justify-around  bg-white w-full h-24 border px-4 py-2 shadow-md'>
                <div className='flex justify-between pr-4'>
                    <div className='text-md font-light'>Bounce Rate</div>
                    <div >:</div>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='text-3xl font-semibold'>{visitorDetail.bounce_rate}</div>
                    {/* <div>16% ^</div> */}
                </div>
            </div>
        </div>
        // </div>
    )
}

export default UserCount