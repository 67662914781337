import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; 

export const getSiteById = async (userId) => {
  try {
    const res = await axios.get(`${API_URL}/site/${userId}`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || 'An error occurred.');
  }
};
