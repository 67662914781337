import React, { useEffect } from 'react'
import UserCount from '../components/dashboard/userCount';
import UserAction from '../components/dashboard/userAction';
import VisitorDetail from '../components/dashboard/userVisitorDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getVisitorDetails, getVisitorInfo } from '../lib/matomo';
import { setVisitorData } from '../store/visitorSlice';
import { setVisitorDetail } from '../store/visitorDetailSlice';
import MyComponent from '../components/dashboard/graph';
import GeoMap from '../components/dashboard/geoMap';

function Dashboard() {
    const dispatch = useDispatch();
    const site = useSelector((state) => state.site);

    useEffect(() => {
        const fetchVisitorInfo = async () => {
            try {
                if (site.idSite !== null) {
                    const visitorInfo = await getVisitorInfo(site.idSite);
                    dispatch(setVisitorData(visitorInfo))
                    const visitotDetails = await getVisitorDetails(site.idSite);
                    dispatch(setVisitorDetail(visitotDetails))

                }
            } catch (error) {
                console.error("Error fetching visitor info:", error);
            }
        };

        fetchVisitorInfo();
    }, [site]);
    return (
        <div className="bg-[#f2f9ff] p-1 flex flex-wrap justify-start ">
            <UserCount />
            <MyComponent />
            {/* <UserAction /> */}
            <VisitorDetail />
            <GeoMap />
        </div>
    )
}

export default Dashboard;