import React, { useState } from 'react';
import { IoIosDesktop } from "react-icons/io";
import { GrSystem } from "react-icons/gr";
import { TbBrowser } from "react-icons/tb";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdAdsClick } from "react-icons/md";
import { useSelector } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './scroll.css';


function VisitorDetail() {
    const [selectedTab, setSelectedTab] = useState('Today');
    const [selectedVisitor, setSelectedVisitor] = useState(null);
    const visitorDetail = useSelector((state) => state.visitorDetail);

    const handleViewClick = (visitor) => {
        setSelectedVisitor(visitor);
    };

    const handleClosePopup = () => {
        setSelectedVisitor(null);
    };

    return (
        <div className='flex-grow m-2 border shadow h-[300px]'>
            <div className='bg-white w-full h-full p-4  '>
                <div className='flex justify-between mb-2'>
                    <div className='text-xl '>Visitor Details</div>
                    <div className='flex text-sm space-x-3'>
                        {['Month', 'Week', 'Today'].map((tab) => (
                            <div
                                key={tab}
                                onClick={() => setSelectedTab(tab)}
                                className={`cursor-pointer px-4 py-1 rounded-2xl ${selectedTab === tab ? 'bg-blue-500 text-white' : ''}`}
                            >
                                {tab}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="overflow-y-auto overflow-x-auto h-[230px] custom-scroll">
                    {visitorDetail.detail?.map((visitor, index) => (
                        <div key={index} className='w-full bg-white border rounded-sm mb-4'>
                            <div className='text-black flex justify-between py-1 px-6 items-center'>
                                <div className='text-black'>
                                    <div className='font-bold text-lg '>
                                        {visitor.visitorId ? visitor.visitorId : 'Anonymous'}
                                        <span className='ml-2 text-black font-light text-sm'>
                                            {visitor.lastActionDateTime}
                                        </span>
                                    </div>

                                </div>
                                <div className='text-black flex space-x-4 items-center'>
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={`Device: ${visitor.deviceType}`}>
                                        <img className='h-4' src={`https://matomo-adrig.centralindia.cloudapp.azure.com/${visitor.deviceTypeIcon}`} alt='device' />
                                        {/* <IoIosDesktop size={28} /> */}
                                    </a>
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={`OS: ${visitor.operatingSystemName}`}>
                                        {/* */}
                                        <img className='h-4' src={`https://matomo-adrig.centralindia.cloudapp.azure.com/${visitor.operatingSystemIcon}`} alt='device' />
                                        {/* <GrSystem size={23} /> */}
                                    </a>
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={`Browser: ${visitor.browserName}`}>
                                        <img className='h-4' src={`https://matomo-adrig.centralindia.cloudapp.azure.com/${visitor.browserIcon}`} alt='device' />
                                        {/* <TbBrowser size={28} /> */}
                                    </a>
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={`Country: ${visitor.country}`}>
                                        <img className='h-4' src={`https://matomo-adrig.centralindia.cloudapp.azure.com/${visitor.countryFlag}`} alt='device' />
                                        {/* <FaMapLocationDot size={28} /> */}
                                    </a>
                                </div>
                                <div className='text-gray-500 text-sm cursor-pointer' onClick={() => handleViewClick(visitor)}>
                                    view
                                </div>
                            </div>
                            <div className='text-black bg-[#f2f8fc] text-sm rounded-b-sm py-0.5 px-4 flex justify-between'>
                                <div className='text-black'>
                                    totalVisitDuration: {visitor?.totalVisitDuration ? visitor.totalVisitDuration : 0}
                                </div>
                                <div className='text-black'>
                                    totalVisits: {visitor.visitCount}
                                </div>
                                <div className='text-black flex'>
                                    Actions:
                                    <div className='text-black flex ml-2 space-x-2 items-center'>
                                        {visitor.actionDetails.slice(0, 5).map((action, actionIndex) => (
                                            <a key={actionIndex} data-tooltip-id="my-tooltip" data-tooltip-html={`Action: ${action.url} <br/> Time: ${action.serverTimePretty}`}>
                                                <MdAdsClick data-tip="Action Click" />
                                            </a>
                                        ))}
                                        {visitor.actionDetails.length > 5 && <span> + </span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <ReactTooltip id="my-tooltip" place="top" effect="solid" />

                {selectedVisitor && (
                    <div className="z-20 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white rounded-lg p-4 w-11/12 md:w-1/3">
                            <h2 className='text-2xl font-semibold mb-4'>Visitor Details</h2>
                            <div>
                                <div>ID: {selectedVisitor.visitorId ? selectedVisitor.visitorId : 'Anonymous'}</div>
                                <div>Last Action: {selectedVisitor.lastActionDateTime}</div>
                                <div>Device: {selectedVisitor.deviceType}</div>
                                <div>OS: {selectedVisitor.operatingSystemName}</div>
                                <div>Browser: {selectedVisitor.browserName}</div>
                                <div>Country: {selectedVisitor.country}</div>
                                <div>Total Visit Duration: {selectedVisitor.totalVisitDuration}</div>
                                <div>Total Visits: {selectedVisitor.visitCount}</div>
                            </div>
                            <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded" onClick={handleClosePopup}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default VisitorDetail;
