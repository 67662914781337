import React from 'react';
import { GoogleLogin, googleLogout, GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { login as loginUser } from '../store/authSlice';
import { setSiteData } from "../store/siteSlice";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { getSiteById } from '../lib/siteApi';

const GoogleAuth = ({ showToast }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientId = '924412817786-qnstr8p176s7157t9vq6c48md1fse0dn.apps.googleusercontent.com';

    const handleSuccess = async (credentialResponse) => {
        try {
            console.log('Login Success:', credentialResponse);
            const response = await fetch('http://localhost:5000/api/auth/google-auth', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    credential: credentialResponse.credential,
                    client_id: clientId,
                }),
            });

            const data = await response.json();
            console.log(data)
            if (data.token) {
                dispatch(loginUser(data));

                const decodedToken = jwtDecode(data.token);
                if (decodedToken.id) {
                    showToast('Login successful!', 'success');
                    const siteData = await getSiteById(decodedToken.id);
                    if (siteData.message === 'success') {
                        dispatch(setSiteData(siteData.data));
                        navigate('/dashboard');
                    } else {
                        navigate('/addsite');
                    }

                }
            } else {
                console.error('Token not found in response');
            }
        } catch (err) {
            console.error('Error during authentication:', err);
        }
    };

    const handleError = () => {
        console.log('Login Failed');
    };

    return (
        <div className='w-full flex justify-center'>
            <GoogleOAuthProvider clientId={clientId}>
                <div className="w-full flex justify-center">
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleError}
                        className="w-full"
                    />
                </div>
            </GoogleOAuthProvider>
        </div>
    );
};

export default GoogleAuth;
