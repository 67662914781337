import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login as loginUser } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import { login } from "../lib/authApi";
import { jwtDecode } from "jwt-decode";
import { getSiteById } from "../lib/siteApi";
import { setSiteData } from "../store/siteSlice";
import GoogleAuth from "../Auth/GoogleAuth";
import Logo from "../assets/tip-1.png";

const Login = ({ showToast }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.id !== null) {
      navigate(site.idSite !== null ? "/dashboard" : "/addsite");
    }
  }, [user, site, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const data = await login(email, password);
      if (data.token) {
        dispatch(loginUser(data));
        const decodedToken = jwtDecode(data.token);
        if (decodedToken.id) {
          const siteData = await getSiteById(decodedToken.id);
          dispatch(
            setSiteData(siteData.message === "success" ? siteData.data : null)
          );
          showToast("Login successful!", "success");
          navigate(siteData.message === "success" ? "/dashboard" : "/addsite");
        }
      }
    } catch (err) {
      showToast(err.message, "error");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl max-w-md mx-auto px-8 pt-4 pb-8 mt-4 mb-12">
      <div className="shrink-0 flex justify-center ">
        <a
          className=" flex items-center sm:order-1 text-black flex-none text-xl font-bold focus:outline-none focus:opacity-80"
          onClick={() => navigate("/")}
        >
          <img className=" h-[70px]" src={Logo} alt="logo" />
        </a>
      </div>
      <h3 className="text-2xl  text-dark-grey-900 text-center mb-2">Welcome</h3>
      <h3 className="text-sm  text-dark-grey-900 mb-6 text-center">
        Log in to tipflow to continue to dashboard.
      </h3>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="flex flex-col">
        <label htmlFor="email" className="text-sm text-grey-900 mb-1">
          Email*
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="mail@loopple.com"
          className=" w-full px-4 py-3 mb-4 bg-grey-200 text-sm text-dark-grey-900 rounded focus:outline-none border-2 focus:border-2 focus:border-[#5D5FEF]"
          required
        />
        <label htmlFor="password" className="text-sm text-grey-900 mb-1">
          Password*
        </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter a password"
          className="w-full px-4 py-3 mb-6 bg-grey-200 text-sm text-dark-grey-900 rounded focus:outline-none border-2 focus:border-2 focus:border-[#5D5FEF]"
          required
        />
        <div className="flex justify-between mb-4">
          <a
            href="javascript:void(0)"
            className="text-sm font-medium text-purple-blue-500"
          >
            Forget password?
          </a>
        </div>
        <button
          className="w-full px-6 py-3 mb-4 text-sm font-bold text-white transition duration-300 bg-[#5D5FEF] hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 rounded"
          type="submit"
          disabled={loading}
        >
          {loading ? "Logging In..." : "Continue"}
        </button>
        <p className="text-sm text-grey-900 ">
          Not registered yet?{" "}
          <a href="/signup" className="font-bold text-grey-700">
            Create an Account
          </a>
        </p>
        <div className="flex items-center my-6">
          <hr className="flex-grow border-grey-500" />
          <span className="mx-2 text-grey-600">or</span>
          <hr className="flex-grow border-grey-500" />
        </div>
        <div className="flex flex-col justify-center">
          <GoogleAuth showToast={showToast} />
        </div>
      </form>
    </div>
  );
};

export default Login;
