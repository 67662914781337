import React from 'react'
import B2bComponent from '../components/b2b'
import Footer from '../components/footer'

function B2b() {
    return (
        <div>
            <B2bComponent />
            <Footer />
        </div>
    )
}

export default B2b