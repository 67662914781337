import React, { useState } from 'react';
import Img1 from '../assets/buy.gif';
import Img2 from '../assets/action.gif';
import Img3 from '../assets/profit.gif';
import Img4 from '../assets/roi.gif';

function Test() {
    const [activeTab, setActiveTab] = useState("tabs-with-card-item-1");

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="relative p-6 md:p-16">
                <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
                    <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
                        {/* <h2 className="text-2xl text-white font-bold sm:text-3xl ">
                            Fully customizable rules to match your unique needs
                        </h2> */}

                        <nav className="grid gap-4 mt-5 md:mt-10" aria-label="Tabs" role="tablist" aria-orientation="vertical">
                            <Tab
                                id="tabs-with-card-item-1"
                                title="10X"
                                description="Conversation Rate - Boosting prospect-to-client conversion rates by 10X."
                                iconPath="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"
                                isActive={activeTab === "tabs-with-card-item-1"}
                                onClick={() => handleTabClick("tabs-with-card-item-1")}
                            />
                            <Tab
                                id="tabs-with-card-item-2"
                                title="2 sec"
                                description="Response Time - Reducing client query response time to under 2 seconds."
                                iconPath="m12 14 4-4M3.34 19a10 10 0 1 1 17.32 0"
                                isActive={activeTab === "tabs-with-card-item-2"}
                                onClick={() => handleTabClick("tabs-with-card-item-2")}
                            />
                            <Tab
                                id="tabs-with-card-item-3"
                                title="27%"
                                description="Revenue - Increasing revenue by 27% through optimized strategies."
                                iconPath="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"
                                isActive={activeTab === "tabs-with-card-item-3"}
                                onClick={() => handleTabClick("tabs-with-card-item-3")}
                            />
                            <Tab
                                id="tabs-with-card-item-4"
                                title="2 months"
                                description="ROI - Confirmed return on investment in 2 months."
                                iconPath="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"
                                isActive={activeTab === "tabs-with-card-item-3"}
                                onClick={() => handleTabClick("tabs-with-card-item-4")}
                            />
                        </nav>
                    </div>
                    <div className="lg:col-span-6">
                        <div className="relative">
                            <div>
                                <TabPanel id="tabs-with-card-1" active={activeTab === "tabs-with-card-item-1"}>
                                    <img
                                        className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/20 w-full h-[500px] object-cover p-12 bg-white"
                                        src={Img1}
                                        alt="Features Image"
                                    />
                                </TabPanel>

                                <TabPanel id="tabs-with-card-2" active={activeTab === "tabs-with-card-item-2"}>
                                    <img
                                        className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/20 w-full h-[500px] object-cover p-12 bg-white"
                                        src={Img2}
                                        alt="Features Image"
                                    />
                                </TabPanel>

                                <TabPanel id="tabs-with-card-3" active={activeTab === "tabs-with-card-item-3"}>
                                    <img
                                        className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/20 w-full  h-[500px] object-cover p-12 bg-white"
                                        src={Img3}
                                        alt="Features Image"
                                    />
                                </TabPanel>
                                <TabPanel id="tabs-with-card-4" active={activeTab === "tabs-with-card-item-4"}>
                                    <img
                                        className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/20 w-full  h-[500px] object-cover p-12 bg-white"
                                        src={Img4}
                                        alt="Features Image"
                                    />

                                </TabPanel>
                            </div>
                            <div className="hidden absolute top-0 end-0 translate-x-20 md:block lg:translate-x-20">
                                <svg className="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                                    <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                                    <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 grid grid-cols-12 size-full">
                    <div className="col-span-full lg:col-span-7 lg:col-start-6 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full bg-gradient-to-r from-indigo-600 to-violet-600"></div>
                </div>
            </div>
        </div>
    );
}

const Tab = ({ id, title, description, iconPath, isActive, onClick }) => (
    <button
        type="button"
        className={`hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 focus:outline-none focus:bg-gray-200 p-4 md:p-5 rounded-xl 
        dark:hs-tab-active:bg-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 ${isActive ? "active" : ""}`}
        id={id}
        aria-selected={isActive}
        role="tab"
        onClick={onClick}
    >
        <span className="flex gap-x-6">
            <svg className="shrink-0 mt-2 size-6 md:size-7 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d={iconPath} />
            </svg>
            <span className="grow">
                <span className="block text-5xl font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200">{title}</span>
                <span className="block text-sm text-white">{description}</span>
            </span>
        </span>
    </button>
);

const TabPanel = ({ id, active, children }) => (
    <div
        role="tabpanel"
        hidden={!active}
        id={id}
        aria-labelledby={id}
        className="overflow-hidden rounded-xl shadow-xl  box-shadow"
    >
        {active && (
            <div className="h-full w-full">
                {children}
            </div>
        )}
    </div>
);

export default Test;
