import { useState, useEffect } from "react";
// import { decodeJWT } from "@/lib/decodeJWT";
import { useNavigate } from "react-router-dom";
import { addSiteInMatomo } from "../lib/matomo";
import { setSiteData } from "../store/siteSlice";
import { useDispatch, useSelector } from "react-redux";

const UserDetailsForm = () => {
  const [userId, setUserId] = useState("");
  const [url, setUrl] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [message, setMessage] = useState("");
  const [jsScript, setJsScript] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Copy");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const site = useSelector((state) => state.site);
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    if (site.url !== null) {
      navigate("/dashboard");
    }
    if (user.id !== null) {
      setUserId(user.id)
    }

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      if (isSubmitted) {
        navigate("/dashboard");
        return;
      }

      const siteName = websiteName;
      const urls = url;

      if (userId !== "") {
        const siteData = await addSiteInMatomo(siteName, urls, userId);
        dispatch(setSiteData(siteData));
        const script = `<script>
                var _paq = window._paq = window._paq || [];
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function() {
                  var u="//matomo-adrig.centralindia.cloudapp.azure.com/";
                  _paq.push(['setTrackerUrl', u+'matomo.php']);
                  _paq.push(['setSiteId', ${String(siteData.idSite)}]);
                  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                })();
              </script>`;
        setJsScript(script);
        setIsSubmitted(true);
      }
    } catch (error) {
      setMessage("Error occurred while submitting data.");
    }
  };

  const handleCopyScript = () => {
    navigator.clipboard.writeText(jsScript).then(() => {
      setCopyButtonText("Copied!");

      setTimeout(() => {
        setCopyButtonText("Copy");
      }, 2000);
    });
  };

  return (
    <div className="max-w-lg mx-auto my-12 p-10 bg-[#171716] rounded-lg shadow-lg">
      <h2 className="text-white text-center mb-5 text-xl font-semibold">
        Add Website Details
      </h2>

      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4">
          <label htmlFor="websiteName" className="block text-white mb-2">
            Website Name:
          </label>
          <input
            type="text"
            id="websiteName"
            value={websiteName}
            onChange={(e) => setWebsiteName(e.target.value)}
            required
            className="w-full h-10 rounded-3xl mt-2 bg-black text-white pl-4 border border-gray-500 focus:border-gray-400"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="url" className="block text-white mb-2">
            Website URL:
          </label>
          <input
            type="url"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
            className="w-full h-10 rounded-3xl mt-2 bg-black text-white pl-4 border border-gray-500 focus:border-gray-400"
          />
        </div>

        {isSubmitted && (
          <div className="mb-6">
            <div className="flex justify-between items-center">
              <label className="text-white">Script</label>
              <button
                type="button"
                className="text-white px-2"
                onClick={handleCopyScript}
              >
                {copyButtonText}
              </button>
            </div>
            <div className="w-full py-4 rounded-3xl mt-2 bg-black text-white pl-4 border border-gray-500">
              {jsScript}
            </div>
          </div>
        )}

        <div className="relative w-full hidden md:flex justify-center items-center mt-6 group">
          <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
          <button
            className="relative w-full inline-flex items-center justify-center py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
            type="submit"
          >
            {isSubmitted ? "Go To Dashboard" : "Submit"}
          </button>
        </div>
      </form>

      {message && (
        <p className="text-center mt-5 text-green-500 text-lg">{message}</p>
      )}
    </div>
  );
};

export default UserDetailsForm;
