import React, { useState } from 'react';

function FAQ() {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "About Tipflow",
            answer: "Tipflow is a cutting-edge SaaS platform designed to revolutionize how businesses generate leads and engage with their customers. Powered by advanced AI technologies, including the GEMINI LLM, Tipflow offers a comprehensive suite of tools that streamline digital marketing efforts, optimize website performance, and automate key sales processes. From personalized chatbots to in-depth analytics and SEO optimization, Tipflow empowers businesses to convert prospects into loyal clients, drive revenue growth, and deliver exceptional customer experiences. Whether you’re a startup or an established enterprise, Tipflow provides the solutions you need to stay ahead in today’s competitive landscape."
        },
        {
            question: "Why Choose Us?",
            answer: "Choosing Tipflow means partnering with a platform that’s dedicated to driving your business success. Our AI-powered tools are designed to maximize your lead generation, optimize your online presence, and automate critical sales processes, all while delivering a personalized experience for your customers. We offer tailored solutions that adapt to your unique business needs, ensuring you stay ahead of the competition. With our commitment to innovation, exceptional support, and proven results, Tipflow not only helps you achieve your goals but exceeds your expectations. Experience growth, efficiency, and customer satisfaction like never before with Tipflow."
        },
        {
            question: "How do I find my purchase history?",
            answer: "At Tipflow, our mission is to empower your business with the tools and insights needed to thrive in the digital age. We offer a comprehensive suite of AI-driven solutions designed to help you attract, engage, and convert more leads, ultimately boosting your sales and revenue. Our platform optimizes your online presence by enhancing SEO performance, providing in-depth user behavior analytics, and implementing personalized engagement strategies like chatbots and smart pop-ups. But we don't stop there. Tipflow also simplifies your sales processes through automation, from managing email campaigns to scheduling meetings, ensuring your team can focus on what they do best—closing deals and nurturing client relationships. With seamless CRM integration and advanced reporting, you’ll have all the data you need to make informed decisions and refine your strategy. We tailor our approach to meet your unique business goals, providing ongoing support and consultation to ensure you’re not just keeping up with the competition but staying ahead. Whether you're a startup looking to scale or an established enterprise aiming to optimize, Tipflow provides the expertise and innovation you need to drive sustained growth and customer satisfaction. Let us help you transform your workflows, enhance your customer interactions, and achieve measurable success."
        },
    ];

    return (
        <section className="py-12">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mb-16">
                    <h6 className="text-lg text-indigo-600 font-medium text-center mb-2">
                        FAQs
                    </h6>
                    <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
                        Frequently asked questions
                    </h2>
                </div>

                <div className="accordion-group">
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={`accordion py-4 px-6 border-b border-solid border-gray-200 transition-all duration-500 rounded-2xl hover:bg-indigo-50 ${openIndex === index ? 'accordion-active:bg-indigo-50' : ''}`}
                        >
                            <button
                                className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-900 w-full transition duration-500 text-left hover:text-indigo-600"
                                onClick={() => toggleAccordion(index)}
                                aria-expanded={openIndex === index}
                            >
                                <h5>{faq.question}</h5>
                                <svg
                                    className={`text-gray-500 transition duration-500 group-hover:text-indigo-600 ${openIndex === index ? 'rotate-180' : ''}`}
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                        stroke="currentColor"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </button>
                            {openIndex === index && (
                                <div
                                    className="accordion-content w-full px-0 overflow-hidden"
                                >
                                    <p className="text-base text-gray-900 leading-6">
                                        {faq.answer}
                                    </p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FAQ;
