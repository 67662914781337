import React from 'react'
import PricingPlan from '../components/pricing'
import Footer from '../components/footer'
import Message from '../components/message'
import FAQ from '../components/faq'

function Pricing() {
    return (
        <div>
            <PricingPlan />
            <Message />
            <FAQ />
            <Footer />

        </div>
    )
}

export default Pricing