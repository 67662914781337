import React, { useRef } from 'react';
import './App.css';
import Login from './pages/login';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/navbar';
import Welcome from './pages';
import SignUp from './pages/signup';
import NavbarWt from './components/navbarWt';
import Dashboard from './pages/dashboard';
import Sidebar from './components/sidebar';
import AddSite from './pages/userdetails';
import Feature from './pages/feature';
import Pricing from './pages/pricing';
import Blog from './pages/blog';
import B2b from './pages/b2b';
import Payment from './pages/payment';
import ToastContainer from './components/common/ToastComponent/ToastComponent';

function App() {
  const location = useLocation();
  const toastRef = useRef();
  const noSideBarPaths = ['/', '/login', '/signup', '/addsite', '/features', , '/pricing', '/blog', '/b2b-community'];
  const isSideBar = !noSideBarPaths.includes(location.pathname);

  const hiddenNavbarPaths = ['/dashboard', '/home'];
  const isNavbar = !hiddenNavbarPaths.includes(location.pathname);

  const showToast = (message, type) => {
    if (toastRef.current) {
      toastRef.current.addToast(message, type);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      {isNavbar ? <Navbar /> : <NavbarWt />}

      <div className="flex flex-1">
        {isSideBar && <Sidebar />}
        <div className={`flex-1  ${isSideBar ? '' : ''}`}>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/login" element={<Login showToast={showToast} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/addsite" element={<AddSite />} />
            <Route path="/home" element={<Payment />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/features" element={<Feature />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/b2b-community" element={<B2b />} />
          </Routes>
        </div>

      </div>
      <ToastContainer ref={toastRef} />
    </div>
  );
}

export default App;
