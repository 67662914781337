import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nb_uniq_visitors: 0,
  nb_users: 0,
  nb_visits: 0,
  nb_actions: 0,
  nb_visits_converted: 0,
  bounce_count: 0,
  sum_visit_length: 0,
  max_actions: 0,
  bounce_rate: '0%',
  nb_actions_per_visit: 0,
  avg_time_on_site: 0,
};

const visitorSlice = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    setVisitorData: (state, action) => {
      state.nb_uniq_visitors = action.payload.nb_uniq_visitors || state.nb_uniq_visitors;
      state.nb_users = action.payload.nb_users || state.nb_users;
      state.nb_visits = action.payload.nb_visits || state.nb_visits;
      state.nb_actions = action.payload.nb_actions || state.nb_actions;
      state.nb_visits_converted = action.payload.nb_visits_converted || state.nb_visits_converted;
      state.bounce_count = action.payload.bounce_count || state.bounce_count;
      state.sum_visit_length = action.payload.sum_visit_length || state.sum_visit_length;
      state.max_actions = action.payload.max_actions || state.max_actions;
      state.bounce_rate = action.payload.bounce_rate || state.bounce_rate;
      state.nb_actions_per_visit = action.payload.nb_actions_per_visit || state.nb_actions_per_visit;
      state.avg_time_on_site = action.payload.avg_time_on_site || state.avg_time_on_site;
    },
    clearVisitorData: (state) => {
      state.nb_uniq_visitors = 0;
      state.nb_users = 0;
      state.nb_visits = 0;
      state.nb_actions = 0;
      state.nb_visits_converted = 0;
      state.bounce_count = 0;
      state.sum_visit_length = 0;
      state.max_actions = 0;
      state.bounce_rate = '0%';
      state.nb_actions_per_visit = 0;
      state.avg_time_on_site = 0;
    },
  },
});

export const { setVisitorData, clearVisitorData } = visitorSlice.actions;
export default visitorSlice.reducer;
