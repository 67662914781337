import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HowWorking from '../components/howWorking';
import Testimonial from '../components/testimonial/testimonial';
import ScheduleDemo from '../components/scheduleDemo';
import Footer from '../components/footer';
import Stats from '../components/statsHero';
import StatsCountComponent from '../components/statsCountComponent';
import Test from '../components/test';
import FeaturesHome from '../components/featuresHome';
import FAQ from '../components/faq';
import ClientMarquee from '../components/cientMarquee';

function Welcome() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);
  useEffect(() => {
    if (user.id !== null) {
      if (site.url !== null) {
        navigate('/addsite');
      } else {
        navigate('/dashboard');
      }
    }
  }, [user]);

  return (
    <div>
      <Stats />
      <ClientMarquee />
      <HowWorking />
      <Test />
      <FeaturesHome />
      <Testimonial />
      <FAQ />
      <ScheduleDemo />
      <Footer />
    </div>
  )
}

export default Welcome;