// src/components/Toast.js
import React, { useEffect, useState } from 'react';
import './Toast.css';

const Toast = ({ message, type }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        const hideTimeout = setTimeout(() => setShow(false), 3000);
        return () => clearTimeout(hideTimeout);
    }, []);

    return (
        <div className={`toast ${type} ${show ? 'show' : 'hide'}`}>
            {message}
        </div>
    );
};

export default Toast;
