import React from 'react'

function B2bComponent() {
    return (
        <div>
            <div class=" py-28 bg-gradient-to-r from-indigo-600 to-violet-600 flex flex-col items-center justify-center">
                <h1 class="text-5xl text-white font-bold mb-8 animate-pulse">
                    Coming Soon
                </h1>
                <p class="text-white text-lg mb-8">
                    We're working hard to bring you something amazing. Stay tuned!
                </p>
            </div>
        </div>
    )
}

export default B2bComponent