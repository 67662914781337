import { createSlice } from '@reduxjs/toolkit';

const loadSiteData = () => {
  const url = localStorage.getItem('site_url');
  const idSite = localStorage.getItem('site_idSite');
  const websiteName = localStorage.getItem('site_websiteName');
  
  return {
    url: url ? JSON.parse(url) : null,
    idSite: idSite ? JSON.parse(idSite) : null,
    websiteName: websiteName ? JSON.parse(websiteName) : null,
  };
};

const saveSiteData = (state) => {
  localStorage.setItem('site_url', JSON.stringify(state.url));
  localStorage.setItem('site_idSite', JSON.stringify(state.idSite));
  localStorage.setItem('site_websiteName', JSON.stringify(state.websiteName));
};

const initialState = loadSiteData();

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSiteData: (state, action) => {
      state.url = action.payload.url || state.url;
      state.idSite = action.payload.idSite || state.idSite;
      state.websiteName = action.payload.websiteName || state.websiteName;
      saveSiteData(state);
    },
    clearSiteData: (state) => {
      state.url = null;
      state.idSite = null;
      state.websiteName = null;

      localStorage.removeItem('site_url');
      localStorage.removeItem('site_idSite');
      localStorage.removeItem('site_websiteName');
    },
  },
});

export const { setSiteData, clearSiteData } = siteSlice.actions;
export default siteSlice.reducer;
