import React from 'react';
import './testimonial.css';
import Avatar from './assets/prabhu.png';
import Avatar2 from './assets/debashisdas.png';
import Avatar3 from './assets/3rdone.jpeg';

const testimonials = [
    {
        name: "Prabhu Chandramoulesshwaran",
        position: "CEO, DataDrone",
        avatar: Avatar,
        rating: 5,
        content: "Tipflow has completely changed how we handle our leads. We used to find it hard to keep in touch with potential clients, but Tipflow makes it so easy. Since using it, our sales have gone up by 30%."
    },
    {
        name: "Debashis Das",
        position: "VP, Apexon",
        avatar: Avatar2,
        rating: 4.5,
        content: "I sat with Vijay and looked at Tipflow's beta, which I definitely want to try. If you’re serious about improving your lead nurturing, I’d say give Tipflow a shot. It seems like a tool that could make a difference in keeping potential clients engaged and boosting your sales."
    },
    {
        name: "Moorthy",
        position: "CEO, Founder, Vminds Technologies",
        avatar: Avatar3,
        rating: 5,
        content: "We’ve worked with Adrig AI Technologies before for the same lead nurturing system, and their service was really good. They handled everything professionally and made sure we were satisfied. Now, I’m eager to try Tipflow."
    }
];

function Testimonial() {
    return (
        <section className="py-12">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mb-12">
                    <h2 className="text-4xl text-center font-bold text-gray-900">What our happy user says!</h2>
                    <p class=" mx-auto text-center mt-4 text-base leading-relaxed text-gray-600">Don't just take our word for it. Here's what real people are saying about Tipflow.</p>

                </div>

                <div className="swiper mySwiper">
                    <div className="swiper-wrapper ">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="swiper-slide w-[400px] min-h-[330px] ">
                                <div className="flex flex-col justify-between group bg-white border border-solid border-gray-300 rounded-xl p-6 transition-all duration-500 w-full h-full mx-auto hover:border-indigo-600 hover:shadow-sm shadow-md">
                                    <div className="">
                                        <div className="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500">
                                            <svg className="w-5 h-5" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z" fill="currentColor" />
                                            </svg>
                                            <span className="text-base font-semibold text-indigo-600">{testimonial.rating}</span>
                                        </div>
                                        <p className="text-base text-gray-600 leading-6 transition-all duration-500 py-1 pb-8 group-hover:text-gray-800">{testimonial.content}</p>
                                    </div>
                                    <div className="flex items-center gap-5 border-t border-solid border-gray-200 pt-5">
                                        <img className="rounded-full h-10 w-10 object-cover" src={testimonial.avatar} alt={testimonial.name} />
                                        <div className="block">
                                            <h5 className="text-gray-900 font-medium transition-all duration-500 mb-1">{testimonial.name}</h5>
                                            <span className="text-sm leading-4 text-gray-500">{testimonial.position}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
