// src/components/ToastContainer.js
import React, { useState, createContext, useContext, useImperativeHandle, forwardRef } from 'react';
import Toast from './Toast';

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

const ToastContainer = forwardRef((_, ref) => {
  const [toasts, setToasts] = useState([]);

  useImperativeHandle(ref, () => ({
    addToast: (message, type) => {
      const id = new Date().getTime();
      setToasts((prev) => [...prev, { id, message, type }]);

      setTimeout(() => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id));
      }, 3000); // 3 seconds duration
    },
  }));

  return (
    <ToastContext.Provider value={ref}>
      <div className="toast-container">
        {toasts.map((toast) => (
          <Toast key={toast.id} message={toast.message} type={toast.type} />
        ))}
      </div>
    </ToastContext.Provider>
  );
});

export default ToastContainer;
