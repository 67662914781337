import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login as loginUser } from '../store/authSlice';
import { signup } from '../lib/authApi';
import GoogleAuth from '../Auth/GoogleAuth';
import Logo from '../assets/tip-1.png';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const data = await signup({ email, password, userName });

      if (data.token) {
        dispatch(loginUser(data));
        navigate('/addsite');
      }
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl max-w-md mx-auto px-8 pt-4 pb-8 mt-4 mb-12">
      <div className="shrink-0 flex justify-center ">
        <a
          className="flex items-center sm:order-1 text-black flex-none text-xl font-bold focus:outline-none focus:opacity-80"
          onClick={() => navigate('/')}
        >
          <img className="h-[70px]" src={Logo} alt="logo" />
        </a>
      </div>
      <h3 className="text-2xl text-dark-grey-900 text-center mb-2">Create an Account</h3>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="flex flex-col">
        <label htmlFor="name" className="text-sm text-grey-900 mb-1">Username*</label>
        <input
          id="name"
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="username"
          autoComplete="off"
          className="w-full px-4 py-3 mb-4 bg-grey-200 text-sm text-dark-grey-900 rounded focus:outline-none border-2 focus:border-2 focus:border-[#5D5FEF]"
          required
        />
        <label htmlFor="email" className="text-sm text-grey-900 mb-1">Email*</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="mail@loopple.com"
          autoComplete="email"
          className="w-full px-4 py-3 mb-4 bg-grey-200 text-sm text-dark-grey-900 rounded focus:outline-none border-2 focus:border-2 focus:border-[#5D5FEF]"
          required
        />
        <label htmlFor="password" className="text-sm text-grey-900 mb-1">Password*</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter a password"
          className="w-full px-4 py-3 mb-6 bg-grey-200 text-sm text-dark-grey-900 rounded focus:outline-none border-2 focus:border-2 focus:border-[#5D5FEF]"
          required
        />
        <button
          className="w-full px-6 py-3 mb-4 text-sm font-bold text-white transition duration-300 bg-[#5D5FEF] hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 rounded"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
        <p className="text-sm text-grey-900">
          Already have an account? <a href="/login" className="font-bold text-grey-700">Log In</a>
        </p>
        <div className="flex items-center my-6">
          <hr className="flex-grow border-grey-500" />
          <span className="mx-2 text-grey-600">or</span>
          <hr className="flex-grow border-grey-500" />
        </div>
        <div className="flex justify-center">
          <GoogleAuth />
        </div>
      </form>
    </div>
  );
};

export default SignUp;
