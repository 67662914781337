import React from 'react'
import BlogComponent from '../components/blogComponent'
import Footer from '../components/footer'
import B2bComponent from '../components/b2b'

function Blog() {
    return (
        <div>
            <B2bComponent />
            <Footer />
        </div>
    )
}

export default Blog