import React from 'react';

const features = [
    {
        title: "Website Analysis & Consultancy",
        description: "Maximize Your Website's Potential by leveraging data-driven insights to identify areas for improvement. Our consultancy services provide tailored strategies to enhance user experience, increase engagement, and boost conversions.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"></path>
            </svg>
        ),
    },
    {
        title: "SEO Optimization & Page Ranking",
        description: "Drive More Organic Traffic through targeted SEO strategies tailored to your business needs. Our team utilizes the latest techniques to improve your website’s ranking on search engines, ensuring visibility and attracting relevant audiences.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"></path>
            </svg>
        ),
    },
    {
        title: "Click-Through Analytics & User Behavior Insights",
        description: "Understand and Engage Your Audience by analyzing user behavior patterns on your website. Our advanced analytics tools provide insights into click-through rates, engagement metrics, and user interactions to optimize content and improve user experience.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
        ),
    },
    {
        title: "Personalised Chatbots Powered by GEMINI LLM",
        description: "Engage Visitors with AI to provide instant responses and assistance. Our chatbots are powered by advanced language models that understand user queries, offer personalized recommendations, and enhance user engagement 24/7.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"></path>
            </svg>
        ),
    },
    {
        title: "AI-Driven LinkedIn Prospecting",
        description: "Find and Convert High-Quality Leads through targeted outreach strategies. Our AI-driven tools analyze profiles and recommend prospects based on your ideal customer profile, enhancing your lead generation efforts on LinkedIn.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 12h-19.5M12 3l6 9H6l6-9z"></path>
            </svg>
        ),
    },
    {
        title: "Email Automation & Meeting Scheduling",
        description: "Nurture Leads and Convert Prospects with streamlined email campaigns and automated scheduling tools. Our solutions help you manage outreach effectively, ensuring timely follow-ups and increased engagement with your leads.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 12v0m0 0h0m0 0l4.5 4.5M12 12h6l-6-6-6 6h6z"></path>
            </svg>
        ),
    },
    {
        title: "CRM Integration",
        description: "Centralize Your Lead Management by integrating seamlessly with popular CRM systems. Our solutions ensure all your leads and customer interactions are organized in one place, making it easier to track progress and manage relationships.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 2.25l8.25 8.25-8.25 8.25-8.25-8.25L12 2.25z"></path>
            </svg>
        ),
    },
];

const futureSteps = [
    {
        title: "Client Success Management (CSM) & Churn Reduction",
        description: "Retain Clients and Reduce Churn by implementing proactive strategies that focus on understanding client needs, addressing concerns, and ensuring satisfaction. Our CSM solutions help you build strong relationships with clients, fostering loyalty and long-term partnerships.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 2.25l8.25 8.25-8.25 8.25-8.25-8.25L12 2.25z"></path>
            </svg>
        ),
    },
    {
        title: "Automated Project Workflows",
        description: "Streamline Operations with automated project management workflows that enhance efficiency and collaboration. Our tools simplify task assignments, progress tracking, and communication, ensuring projects stay on schedule and within budget.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18m-9 9l-6-6m6 6l6-6m-6 0V3"></path>
            </svg>
        ),
    },
    {
        title: "Automated Marketing Campaigns",
        description: "Effortless Campaign Management through automated marketing solutions that simplify the planning, execution, and analysis of campaigns. Our tools help you reach your target audience effectively while optimizing resource allocation and maximizing ROI.",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h8m-4-8v16m0-16l-6 6m6-6l6 6m-6-6l6-6m-6 6l-6-6"></path>
            </svg>
        ),
    },
];


const FeatureComponent = () => {
    return (
        <div className="bg-white py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
                    <h2 className="text-base font-semibold leading-7 text-blue-100 bg-blue-600 px-3 rounded-lg uppercase mb-4 lg:mb-8">
                        Key Features
                    </h2>
                    <h1 className="lg:text-7xl text-4xl md:text-5xl font-bold tracking-tight text-gray-900 text-center">
                        Enhanced{' '}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
                            Tipflow Solutions
                        </span>
                    </h1>
                    <p className="mt-6 text-md text-gray-600 max-w-lg text-center">
                        Discover the exceptional features of our services designed to streamline processes and enhance customer engagement.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature, index) => (
                            <div className="relative pl-16" key={index}>
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-12 w-12 items-center justify-center rounded-lg bg-blue-700">
                                        {feature.icon}
                                    </div>
                                    {feature.title}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    {feature.description}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>

                <div className="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center mt-12">
                    <h2 className="text-base font-semibold leading-7 text-blue-100 bg-blue-600 px-3 rounded-lg uppercase mb-4">
                        Future Steps
                    </h2>
                    <h1 className="lg:text-6xl text-4xl md:text-5xl font-bold tracking-tight text-gray-900 text-center">
                        Expanding {' '}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
                            Tipflow's Capabilities
                        </span>
                    </h1>
                    <p className="mt-6 text-md text-gray-600 max-w-lg text-center">
                        As Tipflow evolves, we will introduce new features to further enhance your lead generation and customer engagement efforts. Here's a glimpse of what's coming next:
                    </p>
                </div>

                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {futureSteps.map((feature, index) => (
                            <div className="relative pl-16" key={index}>
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-12 w-12 items-center justify-center rounded-lg bg-blue-700">
                                        {feature.icon}
                                    </div>
                                    {feature.title}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    {feature.description}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default FeatureComponent;
