import React from 'react';

function ScheduleDemo() {
    return (
        <div className="py-6 mb-8"

        >
            <div className=" mx-12 px-4 py-6 md:px-8 rounded-lg"
                style={{
                    background: 'linear-gradient(90deg,rgba(115,20,247,1)  0%, rgba(37,146,252,1) 100%)',
                }}
            >
                <div class="my-4 text-center lg:text-left flex flex-col justify-center items-center">
                    <p class="text-lg font-medium text-white ">Join Tipflow Today</p>
                    <h2 class="mt-2 text-3xl font-bold text-white sm:text-4xl xl:text-4xl">Nurture leads and generate more revenue with Tipflow. </h2>

                </div>

                <div className='flex justify-center items-center'>
                    {/* <div class="relative inline-flex mt-4 group">
                        <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>

                        <a href="#" title="" class="relative bg-gradient-to-r from-indigo-600 to-violet-600 inline-flex items-center rounded justify-center px-6 py-2 text-lg font-bold text-white transition-all duration-200  font-pj  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900" role="button">
                            Get Started Now
                        </a>
                    </div> */}
                    <div class="relative inline-flex mt-4  group">
                        <div class="absolute transitiona-all  duration-1000 opacity-70 -inset-px  rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>

                        <a href="#" title="" class="relative inline-flex bg-white items-center justify-center rounded-xl px-6 py-2 text-sm font-bold border border-1 border-gray-300 transition-all duration-200 " role="button">
                            Schedule a Demo
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ScheduleDemo;
