import React from 'react';
import StatsImg from '../assets/ss.gif';

function Stats() {
    return (
        <div className="max-w-[85rem] mx-auto px-4">
            {/* Grid */}
            <div className="grid md:grid-cols-2 gap-12 md:items-center">
                <div className='mb-12'>
                    <p class="inline-flex px-4 py-1 mb-2 text-[12px] text-gray-900 border border-gray-200 rounded-full font-pj">Crafted to empower businesses, driving growth and success.</p>

                    <h1 className="text-5xl font-bold text-gray-800 sm:text-2xl lg:text-5xl lg:leading-tight ">
                        <span className="text-indigo-600">Tipflow Gemini</span>
                        - Powered SaaS. Nurture Leads, Boost Success.
                    </h1>
                    <p className="mt-4 text-sm text-gray-600 dark:text-neutral-400 pr-8">
                        Boost Revenue by Nurturing and Converting Prospects to Clients.
                    </p>

                    {/* Buttons */}
                    <div className="mt-8 space-x-4">
                        <a
                            className="py-3 px-6 inline-flex items-center justify-center gap-x-2 rounded-lg bg-indigo-600 text-white text-sm font-medium transition-transform transform hover:scale-105 focus:outline-none focus:scale-105"
                            href="#"
                        >
                            Get started
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="shrink-0"
                            >
                                <path d="M9 18l6-6-6-6" />
                            </svg>
                        </a>
                        <a
                            className="py-3 px-6 inline-flex items-center justify-center gap-x-2 rounded-lg border border-gray-300 bg-white text-gray-800 shadow-sm hover:bg-gray-50 dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 transition-transform transform hover:scale-105 focus:outline-none focus:scale-105"
                            href="#"
                        >
                            Contact sales team
                        </a>
                    </div>
                </div>

                {/* Animated Section */}
                <div className="relative p-24">
                    <div className="bg-gradient-to-r from-indigo-600 to-violet-600 px-20 py-24 rounded-full transform transition-transform duration-500 hover:rotate-6 hover:scale-105">
                        <img src={StatsImg} className="w-full h-auto my-2" alt="Stats Animation" />
                    </div>

                    {/* Stats boxes */}
                    <div className="absolute flex flex-col items-center top-14 right-24 bg-gray-100 py-4 px-8 rounded-lg shadow-lg transition-all transform hover:scale-110">
                        <span className="text-green-600 text-xl font-extrabold">2 months</span>
                        <p className="text-gray-700 text-sm font-medium"> ROI</p>
                    </div>


                    <div className="absolute flex flex-col items-center top-48 right-4 bg-gray-100 p-4 rounded-lg shadow-lg transition-all transform hover:scale-110">
                        <span className="text-green-700 text-2xl font-extrabold">2 sec</span>
                        <p className="text-gray-700 text-sm font-medium">Response Time</p>
                    </div>

                    <div className="absolute flex flex-col items-center top-20 left-24 bg-gray-100 p-4 rounded-lg shadow-lg transition-all transform hover:scale-110">
                        <span className="text-green-700 text-2xl font-extrabold">27%</span>
                        <p className="text-gray-700 text-sm font-medium">Revenue Growth</p>
                    </div>

                    <div className="absolute flex flex-col items-center bottom-28 left-8 bg-gray-100 py-4 px-8 rounded-lg shadow-lg transition-all transform hover:scale-110">
                        <span className="text-green-700 text-4xl font-extrabold">10x </span>
                        <p className="text-gray-700 text-md font-medium">Conversion Rate</p>
                    </div>
                </div>
            </div>
            <div class="">
                <img class="object-cover object-top w-full h-auto mx-auto scale-150 2xl:max-w-screen-2xl xl:scale-100" src="https://d33wubrfki0l68.cloudfront.net/54780decfb9574945bc873b582cdc6156144a2ba/d9fa1/images/hero/4/illustration.png" alt="" />
            </div>
        </div>
    );
}

export default Stats;
