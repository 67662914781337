import React from 'react';
import './Styles/ClientMarquee.css';
import Comp1 from '../assets/clients/cgc-black.png';
import Comp2 from '../assets/clients/elan-black.png';
import Comp3 from '../assets/clients/vedik-black.png';
import Comp4 from '../assets/clients/vminds-black.png';

const ClientMarquee = () => {
    const images = [Comp1, Comp2, Comp3, Comp4];

    return (
        <div className="marquee-container mx-32  my-12 ">
            <div className="marquee ">
                {images.map((url, index) => (
                    <div key={index} className="marquee-item bg-grey-400 p-2">
                        <img src={url} alt={`client-${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClientMarquee;
