// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import siteReducer from './siteSlice';
import visitorReducer from './visitorSlice';
import visitorDetailReducer from './visitorDetailSlice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      auth: authReducer,
      site: siteReducer,
      visitor: visitorReducer,
      visitorDetail: visitorDetailReducer
    },
  });
}
