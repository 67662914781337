import React from 'react';

function StatsCountComponent() {
    const stats = [
        {
            data: "10x",
            title: "Conversion Rate",
            subtitle: "Helping clients achieve a significant increase",
        },
        {
            data: "2 sec",
            title: "Response Time",
            subtitle: "Lightning-fast support and resolutions",
        },
        {
            data: "27%",
            title: "Revenue Growth",
            subtitle: "Consistent year-over-year growth",
        },
        {
            data: "2 months",
            title: "ROI",
            subtitle: "Achieving a full return on investment",
        },
    ];

    return (
        <section className="py-8">
            <div className="max-w-screen-3xl mx-auto px-4 text-gray-600">
                <div className="mx-auto text-center flex flex-col items-center">
                    <h3 className="text-gray-800 sm:text-4xl lg:text-5xl font-semibold">
                        How does it work?
                    </h3>
                    <p className="max-w-2xl mt-4">
                        Helping our clients achieve substantial improvements, from conversion rates to revenue growth, through optimized strategies and fast execution.
                    </p>
                </div>
                <div className="mt-16">
                    <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
                        {stats.map((item, idx) => (
                            <li key={idx} className="text-center px-12 md:px-8">
                                <h4 className="text-7xl text-indigo-600 font-semibold">{item.data}</h4>
                                <p className="mt-3 font-medium text-gray-700">{item.title}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.subtitle}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default StatsCountComponent;
